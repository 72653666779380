import React, { useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Card,
  Box,
  CardMedia,
  Paper,
  CardContent,
} from "@mui/material";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

/**
 * @description: Represents the HomePage component which provides an overview and instructions for the Workbench application.
 * @returns: Renders a detailed description and a step-by-step instruction guide for the Workbench application.
 * @example:
 * <HomePage />
 */

function HomePage() {
  const [index, setIndex] = useState(0);
  const items = [
    {
      title: "Step 1",
      image: "images/step1.PNG",
      description: "Select a Schema within the lefthand sidebar",
    },
    {
      title: "Step 2",
      image: "images/step2.PNG",
      description: "Click 'Select Schema' Button",
    },
    {
      title: "Step 3",
      image: "images/step3.PNG",
      description: "Enter Credentials (Email and API key), and click Submit",
    },
    {
      title: "Step 4",
      image: "images/step4.PNG",
      description:
        "Enter Query Details. Fill out 'Query Name', 'Version', 'Page Number' and 'Number of Objects'. 'Query Name' labels this specfic query. 'Version' denotes the version of the query. 'Page Number' denotes what page in the database you are querying from. 'Number of Objects' denotes how many results you want to retreive from the 'Page Number' specified. Hover over each input field for a tooltip to receive a more detailed explanation.",
    },
    {
      title: "Step 5",
      image: "images/step5.PNG",
      description:
        "Click 'Add' button to add a new row of search parameters. One Parameter must be added at a minimum. Unwanted parameter rows can be deleted by clicking on the individual red 'x' buttons at the end of each row. To clear ALL parameter rows, click the 'clear' button. All 4 fields in each row must be filled in. The last field is the condition field - And/Or/End. In the example image below, this set of parameter translates to: all Multifamily Categories within the zip codes 90703 OR 11101.",
    },
    {
      title: "Step 6",
      image: "images/step6.PNG",
      description:
        "Click 'Get Results' in the 'Results Panel' to retreive your specific query results. Wait a moment to retreive data, which will eventually populate the 'Results Panel'.",
    },
    {
      title: "Step 7",
      image: "images/step7.PNG",
      description:
        "Two features exist at the bottom of the 'Results Panel'. The 'Copy JSON output to clipboard' will copy your query request to your computers' clipboard, which you can now 'paste' in other programs. The 'Convert Results to CSV file' will save the results you requested to a CSV file in your local computer.",
    },
  ];

  return (
    <>
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        Description:
      </Typography>
      <Typography paragraph>
        The Dynamic Query Workbench allows you to easily introspect Moody's
        Analytics CRE databases, by creating JSON payloads to query databases,
        display the databases' response. The workbench also provides ways for
        users to easily copy the JSON Payload to their local clipboard, as well
        as download the database results into a .CSV file.
      </Typography>

      <Typography variant="h5" sx={{ mb: 3 }} fontWeight={"bold"}>
        Using this Workbench:
      </Typography>
      <Grid container direction="row" spacing={3} alignItems="flex-start">
        <Grid item xs={1} style={{ alignSelf: "center" }}>
          <Button
            variant="contained"
            onClick={() => setIndex((index - 1 + items.length) % items.length)}
          >
            <KeyboardDoubleArrowLeftIcon />
          </Button>
        </Grid>

        <Grid item xs={10}>
          <Paper
            sx={{
              height: 500,
            }}
            elevation={3}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {items[index].title}
              </Typography>
              <Typography variant="body2">
                {items[index].description}
              </Typography>
            </CardContent>
            <Box>
              <CardMedia
                sx={{
                  borderTop: 1,
                  borderTopColor: "#CBCBCD",
                  objectFit: "contain",
                  maxHeight: 300,
                }}
                image={items[index].image}
                title={items[index].title}
                component="img"
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={1} style={{ alignSelf: "center" }}>
          <Button
            variant="contained"
            onClick={() => setIndex((index + 1) % items.length)}
          >
            <KeyboardDoubleArrowRightIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default HomePage;
