/**
 * Processes the current state to generate a structured output object for a query, which includes the query name, version, 
 * each parameter row. 
 * It applies a filtering mechanism to include only non-empty parameters and 
 * performs type conversion as defined by the parameter's schema. This function ensures state updates only when changes 
 * are detected in the output to optimize performance by avoiding unnecessary re-renders.
 * 
 * @param {Object} state - The current global state object containing the query configuration and parameters.
 * @param {Function} setState - The function to update the state - namely the 'output' key.
 */

export const updateOutputHelper = (state, setState) => {
  const { queryName, version, pageNum, pageLength, parameters } = state;

  // Filtering the parameters to remove empty ones

  // array of key, operator, value and conditions for each param
  const filteredParameters = parameters.filter(
    (param) => param.key || param.operator || param.value || param.condition
  );

  const output = {};

  if (queryName) output.queryName = queryName;
  if (version) output.version = parseInt(version);

  let paramterObject;

  if (filteredParameters.length) {
    paramterObject = filteredParameters.reduce((acc, parameter) => {
      const { key } = parameter;

      const parameterCopy = { ...parameter };

      // convert param value to integer based on schema type

      if (
        state.propertySchema["properties"][key]["type"] &&
        state.propertySchema["properties"][key]["type"] === "integer"
      ) {
        parameterCopy.value = parseInt(parameterCopy.value);
      }

      //delete keys from JSON output
      delete parameterCopy.key;
      delete parameterCopy.isValid;

      return { ...acc, [key]: parameterCopy };
    }, {});

    if (pageNum) {
      paramterObject = { ...paramterObject, pageNum: parseInt(pageNum) };
    }

    if (pageLength) {
      paramterObject = {
        ...paramterObject,
        pageLength: parseInt(pageLength),
      };
    }
    output.parameters = paramterObject;
  }

  // Convert to string only if the output object has keys
  const JsonOutputString = Object.keys(output).length
    ? JSON.stringify(output, null, 2)
    : "";

  // Update state only if the new output is different from the current output
  if (JsonOutputString !== state.output) {
    setState((prevState) => ({
      ...prevState,
      output: JsonOutputString,
    }));
  }

};
