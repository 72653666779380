// Retrieve baseURL from .env file for local testing
// choices: 'nprd', 'prd'. any other input will result in local 'qa' env
export const getLocalEnvBaseUrl = (env) => {
  if (env === "nprd") {
    return process.env.REACT_APP_NPRD;
  } else if (env === "prd") {
    return process.env.REACT_APP_PRD;
  } else {
    return process.env.REACT_APP_QA;
  }
};
