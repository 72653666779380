/**
 * Validates the values provided for pagination controls, specifically for 'pageNum' (page number) and 'pageLength'
 * (number of items per page), ensuring that they adhere to the constraints of being positive integers. Ensures 'pageLength',
 * does not exceed 150. If the validation fails, an appropriate error message is generated and stored in the error log within the state.
 *
 * @param {string} name - The name identifier of the pagination input field - either 'pageNum' or 'pageLength'.
 * @param {string} value - The value entered in the pagination input field that needs to be validated.
 * @param {Object} state - The current global state object - which contains the error log.
 * @param {Function} setState - The function to set the new state - namely with the updated error log.
 */
export const validatePagination = (name, value, state, setState) => {
  let valid;
  if (name === "pageNum") {
    valid = Number.isInteger(Number(value)) && Number(value) > 0;
  }
  if (name === "pageLength") {
    valid =
      Number.isInteger(Number(value)) &&
      Number(value) > 0 &&
      Number(value) < 150;
  }

  let errorMessage;

  if (!valid && name === "pageNum") {
    errorMessage = `Invalid ${name}. It should be a positive integer.`;
  }

  if (!valid && name === "pageLength") {
    errorMessage = `Invalid ${name}. It should be a positive integer between 0-150.`;
  }

  let errorLog = { ...state.errorLog };
  if (!valid) {
    errorLog = {
      ...errorLog,
      [name]: { key: name, message: errorMessage },
    };
  } else {
    const { [name]: _, ...rest } = errorLog;
    errorLog = rest;
  }

  setState((prevState) => ({
    ...prevState,
    errorLog,
  }));
};
