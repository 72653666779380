import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../hooks/StateContext";
import { TokenContext } from "../hooks/TokenContext";
import LoginModal from "./LoginModal";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";

import config from "../config/primaryConfig.json";

/** Displays the Schemas on the Sidebar
    @description: Generates a list of the Schemas on the sidebar, for users to select
    @param sidebarWidth - width of sidebar 
    @param setSelectedSchemaName - sets teh name of selected schema. The selectedSchemaName is used throughout the app, when a component needs to know the selected schema.
    @param fetchBaseURL - passing prop down into the loginModal component
    @returns {JSX.Element} A rendered component of the sidebar
    */

function SideBarSchema({ sidebarWidth, setSelectedSchemaName, fetchBaseUrl }) {
  const [selectedSchemaIndex, setSelectedSchemaIndex] = useState(null);
  const { state, setState } = useContext(StateContext);
  const { tokenState, setToken } = useContext(TokenContext);

  const schemaList = Object.keys(config).map((schema) => {
    return config[schema].jsonSchema;
  });


  const getSchemaName = (schemaValue) => {
    const schemaNameFound = Object.entries(config).find(
      ([key, value]) => value.jsonSchema === schemaValue
    );

    const schemaName =
      schemaNameFound[0].charAt(0).toUpperCase() + schemaNameFound[0].slice(1);

    return schemaNameFound ? schemaName : null;
  };

  /**
   * @description: When a user selects a Schema option in the Sidebar, update the stateContext with the selected Schema. Changed Schema also resets Token state, which will show the HomePage component. Edge case: Selecting the selectedSchema that was already selected previously, will not update Schema's.
   * @param: Takes in a button click event
   * @returns: updates the stateContext propertySchema
   */

  const handleSchemaChange = (text, index) => {
    /** About the use of text instead of event.target.innerHTML (from chatgpt)
     * replaced with text variable to avoid side effects from using event.target
     * the nesting of the innerText makes it potentially harder to retrieve based on where the user clicks in the buttons
     * performance: inntext property forces browser to compute the text, which is a tad slower, than already having the variable
     */

    const selectedSchema = text;

    setSelectedSchemaIndex(index);

    setSelectedSchemaName(getSchemaName(text)); // set's name of SelectedSchema to be used throughout App (namely within QueryBuilder)

    setState((prevState) => ({
      ...prevState,
      queryName: "query1", // Reset queryName
      version: "1", // Reset version
      selectedSchema,
      parameters: [],
      output: "",
      errorLog: {},
    }));

    setToken((prevState) => ({
      ...prevState,
      validQuery: false,
      validResults: false,
    }));
  };

  /**
   * @description This effect is responsible for loading the relevant schema based on the selected schema name.
   * It first determines the path to the schema using the getSchemaPath function and then dynamically imports it.
   * After a successful import, it updates the global state with the loaded schema.
   *
   * @function
   * @effects It watches for changes in the state.selectedSchema and runs the effect accordingly.
   *
   * @dependencies state.selectedSchema, setState
   */

  useEffect(() => {
    loadSchemas();
  }, [state.selectedSchema]);

  const getSchemaPath = (configObject, schema) => {
    return Object.keys(configObject).find(
      (key) => configObject[key].jsonSchema === schema
    );
  };
  const loadSchemas = async () => {
    const configKey = getSchemaPath(config, state.selectedSchema);
    const schemaPath = config[configKey]?.jsonSchema;

    if (!schemaPath) {
      return;
    }

    try {
      const loadedSchema = await import(`../schemas/${schemaPath}`);
      if (loadedSchema?.default) {
        setState((prevState) => ({
          ...prevState,
          propertySchema: loadedSchema.default,
        }));
      } else {
        console.warn(`Failed to load schema from path: ${schemaPath}`);
      }
    } catch (error) {
      console.error("Error loading the schema:", error);
    }
  };

  return (
    <>
      <Drawer
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sidebarWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box display="flex" justifyContent="center">
          <Box component="img" maxHeight="64px" src="/mabanner.gif" />
        </Box>

        <Divider />
        <ListItem disablePadding>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              pt: "10px",
            }}
          >
            <Typography variant="h5">Select a Schema</Typography>
          </Box>
        </ListItem>
        <List>
          {schemaList.map((text, index) => (
            <ListItem key={text} value={text} disablePadding>
              <ListItemButton
                onClick={(event) => handleSchemaChange(text, index)} // moved onClick to listItemButton - selection placed in Text results in finicky behavior
                sx={{
                  backgroundColor:
                    selectedSchemaIndex === index
                      ? "primary.main"
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      selectedSchemaIndex === index ? "primary.dark" : "",
                  },
                }}
              >
                <ListItemText
                  primary={getSchemaName(text)}
                  sx={{
                    color: selectedSchemaIndex === index ? "white" : "black",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box p={3}>
          <LoginModal fetchBaseUrl={fetchBaseUrl} />
        </Box>
      </Drawer>
    </>
  );
}
export default SideBarSchema;
