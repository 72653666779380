import { Parser } from "@json2csv/plainjs";
import { flatten } from "@json2csv/transforms";
import React, { useEffect, useState, useContext } from "react";
import primaryConfig from "../config/primaryConfig.json";
import { TokenContext } from "../hooks/TokenContext";

import { Button, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { StateContext } from "../hooks/StateContext";

/** Convert results to downloaded CSV file
    @description: When user clicks 'Covert to CSV', the result data is converted from JSON to csv and is downloaded to the user's computer.
    @param propertyData - response from database, to be converted to csv
    @param selectedSchemaName - name of selected schema. Used to retrieve the correct csv_schemas file
    @returns {JSX.Element} A button that has the ability to download a CSV file of the results.
    */

const JsonToCsv = ({ propertyData, selectedSchemaName }) => {
  const [csvFields, setCsvFields] = useState(null);
  const { tokenState } = useContext(TokenContext);
  const { state } = useContext(StateContext);
  const theme = useTheme();

  let selectedSchemaNameConfigKey = selectedSchemaName.toLowerCase();

  // Load the CSV schema from a dynamic import based on the config.

  useEffect(() => {
    import(
      `../csv_schemas/${primaryConfig[selectedSchemaNameConfigKey].csvSchema}.js`
    )
      .then((module) => setCsvFields(module.default))
      .catch((error) => console.log("error loading fields " + error));
  }, [selectedSchemaName]);

  const convertToCsv = () => {
    // flatten the nested JSON object. Seperate nested keys with '_'
    if (tokenState.validResults === false) {
      alert("Get Valid Results First");
    } else {
      const transformParam = [
        flatten({ object: true, array: false, separator: "_" }),
      ];

      const options = {
        fields: csvFields,
        transforms: transformParam,
        defaultValue: "null",
      };

      const myData = propertyData.data;

      // Replace nulls with "N/A" (does not work for nested objects)
      function transformNullToNA(obj) {
        // If obj is an object (but not an array), iterate through its properties
        if (typeof obj === "object" && obj !== null) {
          for (let key in obj) {
            if (obj[key] === null) {
              obj[key] = "N/A";
            } else if (typeof obj[key] === "object") {
              transformNullToNA(obj[key]);
            }
          }
        }
        return obj;
      }

      const transformedData = transformNullToNA(myData);

      // Parser function readme:
      // https://github.com/juanjoDiaz/json2csv/blob/main/packages/plainjs/README.md#parser
      
      const parser = new Parser(options);
      const csv = parser.parse(transformedData);

      // Download CSV file
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");

      // Get date for file name
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
      const day = currentDate.getDate();
      const year = currentDate.getFullYear();

      const formattedDate = `${month}/${day}/${year}`;

      link.href = URL.createObjectURL(blob);
      link.download = `${state.queryName}_ver${state.version}_${formattedDate}.csv`;

      document.body.appendChild(link);
      link.click();

      // DOM cleanup: remove the link element from the DOM and revoke the object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  return (
    <>
      <Tooltip arrow title="Download results in a CSV file format">
        <span>
          <Button
            variant="contained"
            onClick={convertToCsv}
            disabled={!tokenState.validQuery || !tokenState.validResults}
            sx={{
              backgroundColor: tokenState.validResults
                ? theme.palette.primary.main
                : theme.palette.grey[500],
              "&:hover": {
                backgroundColor: tokenState.validResults
                  ? theme.palette.primary.main
                  : theme.palette.grey[500],
              },
            }}
          >
            Convert Results to CSV File
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default JsonToCsv;
