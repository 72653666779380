import React, { useState, useContext } from "react";
import { TokenContext } from "../hooks/TokenContext";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  Grid,
  Snackbar,
  Tooltip,
} from "@mui/material";

import base64 from "base-64"

/**
 * @description: A component that renders a modal for users to enter their credentials (email and API key). Upon submission,
 * it fetches an access token using the provided credentials and updates the token context.
 * @param fetchBaseURL - baseURL is related to environment, as is used for the fetch call for the token.
 * @returns  {JSX.Element} A button which, when clicked, shows the credentials modal, and a snackbar that provides feedback on the login attempt.
 */

function LoginModal({ fetchBaseUrl }) {
  // Initialize state and context values
  const { tokenState, setToken } = useContext(TokenContext);

  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const [loginNotification, setLoginNotification] = useState(false);

  const [email, setEmail] = useState("");
  const [apiKey, setApiKey] = useState("");

  const [validTokenMsg, setValidTokenMsg] = useState("");

  // Handlers for modal open/close
  const handleSignInOpen = () => setSignInModalOpen(true);
  const handleSignInClose = () => setSignInModalOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // Handlers for updating email and API key
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  // Handlers for notification/snackbar open/close
  const openLoginNotification = () => {
    setLoginNotification(true);
  };

  const closeLoginNotification = () => {
    setLoginNotification(false);
  };

  /** Get Access Token
   * @description: After user inputs email, api_key. User clicks 'Get Token' button to make POST request to get Token. Error handling built in to display if valid token is presented.
   * @param: Email address and Api Key
   * @returns: Access Token, which is stored in state.
   * @todo: consider if the access token is housed in State Context, to be able to be used in other components (ie. results panel)
   */

  function getToken(event) {
    //prevent rerender, which is default behavior of form submission response
    event.preventDefault();
    // clearResults()
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + base64.encode(email + ":" + apiKey)
    );

    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${fetchBaseUrl}/auth/token`, requestOptions)
      .then((response) => {
        return response.json(); // returns the promise
      })
      .then((result) => {
        // received the resolved value from previous step, which is the parsed JSON Data
        setToken((prevState) => ({ ...prevState, token: result.access_token }));
        handleSignInClose();

        // Invalid Token
        if (
          result.message ===
          "Could not get the access token - invalid credentials" ||
          result.message === "Invalid username"
        ) {
          setValidTokenMsg("Token is Unacceptable, Re-Enter Credentials");
          openLoginNotification();
          setToken((prevState) => ({ ...prevState, validToken: false }));
          // Valid Token
        } else {
          setValidTokenMsg("Token Accepted");
          openLoginNotification();
          setToken((prevState) => ({ ...prevState, validToken: true }));
        }
      })
      // Invalid Token Catch-all
      .catch((error) => {
        //show snackbar with invalid token
        setValidTokenMsg(
          "Token is Unacceptable, Re-Enter Credentials. This is the error: " +
          error
        );
        setToken((prevState) => ({ ...prevState, validToken: false }));
        handleSignInClose();
        openLoginNotification();
      });
  }

  /** Show Login Notification/Snackbar
   * @description: Login Notification/snackbar rendering if Credentials are correct or incorrect. Dynamic, in that it can either notes that Login is sucessful or not.
   * @param:
   * @returns: JSX rendering of the notification/snackbar
   * @todo: Need to fix stretching close icon
   */

  const LoginNotificationJSX = (
    <>
      <Button color="warning" size="small" onClick={closeLoginNotification}>
        close
      </Button>
    </>
  );

  return (
    <>
      <Tooltip
        arrow
        title="Select A Schema from list above first, then click this button to enter credentials."
      >
        <Button onClick={handleSignInOpen} variant="contained">
          Select Schema
        </Button>
      </Tooltip>
      <Modal
        open={signInModalOpen}
        onClose={handleSignInClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Input Credentials
          </Typography>
          <form onSubmit={getToken}>
            <Grid container spacing={1} pt={2}>
              <Grid item xs={6}>
                <Typography sx={{ mt: 2 }}>Email:</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  onChange={handleEmailChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} pt={2}>
              <Grid item xs={6}>
                <Typography sx={{ mt: 2 }}>API Key:</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  onChange={handleApiKeyChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} pt={4}>
              <Grid item xs={6}>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleSignInClose} variant="outlined">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={loginNotification}
        autoHideDuration={4000}
        onClose={closeLoginNotification}
        message={validTokenMsg}
        action={LoginNotificationJSX}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </>
  );
}

export default LoginModal;
