import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { teal, purple, orange, red } from '@mui/material/colors';


/** Custom Themes for MUI
 * 
 * Default Theme object structure: https://mui.com/material-ui/customization/default-theme/
 * Built in MUI color objects: https://mui.com/material-ui/customization/color/
 * 
 * Gotham Font used per CRE catalyist style guide (looks like it cost money)
 * https://design.catylist.com/foundations/typography
 */

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#003584',
            light: '#001D4C', // switched dark to light, as it seemed more intuitive that an onHover event would produce a brightening of the button, not a darkening of button
            dark: '#0051B3'
        },
        secondary: {
            main: '#FAC814',
            light: '#D4A006',
            dark: '#FFDB3D'
        },
        info: {
            main: '#096AD9',
            light: '#0051B3',
            dark: '#1D89FC'

        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: ".85em",
                },
            }
        },
    },
    typography: {
        fontFamily: [
            'Rubik', 'sans-serif'
        ].join(',')
    }
}
);

export default customTheme