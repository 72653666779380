import "react-json-view-lite/dist/index.css";
import "../App.css";
import React, { useState, useContext, useEffect } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import { StateContext } from "../hooks/StateContext";
import { TokenContext } from "../hooks/TokenContext";

import {
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  Tooltip,
  Snackbar,
} from "@mui/material";

import JsonToCsv from "./JsonToCsv";

import config from "../config/primaryConfig.json";

/**
 * @description The ResultsPanel component displays the results to the user based on the user's query.
 * It provides an interface to fetch results from an API based on a query configuration,
 * displays a loading spinner while fetching, and shows the results or an error message based on the response.
 * It also allows the user to clear the results. The ability to Copy the JSON Request is also built into this
 * component for UI placement reasons. The ability to download the response into a CSV file is also included
 * in this component.
 *
 * @param {string} fetchBaseURL: contains the baseURL which is dependent on the environment. This baseURL is used in the fetch request for the results.
 * @param {string} selectedSchemaName contains the name of the selected schema, which is used to hit the correct endpoint.
 * @returns {JSX.Element} A rendered results panel component.
 *
 */
function ResultsPanel({ fetchBaseUrl, selectedSchemaName }) {
  const { state } = useContext(StateContext);
  const { tokenState, setToken } = useContext(TokenContext);

  const [isClipboardNotificationOpen, setIsClipboardNotificationOpen] =
    useState(false);
  const [copyClipboardMsg, setCopyClipboardMsg] = useState("");

  const [propertiesResults, setPropertiesResults] = useState();
  const [isLoadingPropertiesResults, setIsLoadingPropertiesResults] =
    useState(false);

  // clear property results whenever the Selected Schema changes
  useEffect(() => {
    setPropertiesResults();
  }, [state.selectedSchema]);

  /**
   * @description Clears the displayed Property Results.
   */
  function clearResults() {
    setPropertiesResults();
  }

  /**  Get Properties Data
  @description: When users click 'Get Results' - this function displays the Properties data on screen. Loading screen is implemented while Results are being returned generated. If invalid token was inserted, screen alerts users.
  @returns: Properties data by updating the PropertiesResults state. This component then renders that state using the JSONView library.
  */

  function getResults() {
    if (tokenState.validToken === false) {
      alert("Invalid token. Insert valid Token and try again to get results.");
    } else {
      // setup loading spinner
      setIsLoadingPropertiesResults(true);

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + tokenState.token);

      const raw = state.output;

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        mode: "cors",
      };

      const getEndpointPathParameters = (configObject, schema) => {
        const schemaName = Object.keys(configObject).find(
          (key) => configObject[key].jsonSchema === schema
        );

        return config[schemaName]["endpoint"];
      };

      const endpointPathParams = getEndpointPathParameters(
        config,
        state.selectedSchema
      );

      /**
       * Fetches data from API endpoint and handles the response. A correct response will result in update the PropertiesResults state, which displays the results inside this ResultsPanel. ValidResults will also be set to true, which will un-disable the JsonToCsv button. Regardless of a successful or unsuccessful response - the isLoadingPropertiesResults will be set to false, turning off the spinning loading icon.
       *
       * @param {string} fetchBaseUrl - The base URL of the API.
       * @param {string} endpointPathParams - The path and query parameters - related to the user's selected Schema
       * @param {object} requestOptions - Additional options for the fetch request.
       */

      fetch(`${fetchBaseUrl}${endpointPathParams}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setPropertiesResults(JSON.parse(result));
          setToken((prevState) => ({ ...prevState, validResults: true }));
        })
        .catch((error) => {
          setToken((prevState) => ({ ...prevState, validResults: false }));
        })
        .finally(() => {
          setIsLoadingPropertiesResults(false);
        });
    }
  }

  const closeClipboardNoficiation = () => {
    setIsClipboardNotificationOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(state.output)
      .then(() => {
        setIsClipboardNotificationOpen(true);
        setCopyClipboardMsg("JSON output copied to clipboard!");
      })
      .catch(() =>
        setCopyClipboardMsg("Failed to copy JSON output to clipboard!")
      );
  };

  // JSX rendering related to SnackBar notification when Copy to clipboard occurs
  const copyToClipboardNotificationJSX = (
    <>
      <Button color="warning" size="small" onClick={closeClipboardNoficiation}>
        close
      </Button>
    </>
  );

  return (
    <>
      <Paper elevation={3}>
        <Box padding={2}>
          <Typography variant="h5">Results</Typography>
          <Grid container spacing={6} py={2}>
            <Grid item xs={6}>
              <Tooltip
                arrow
                title="Retrieve results based your Query. Please ensure all Query field's and valid Query Parameters are added prior to clicking 'Get Results'."
              >
                <span>
                  {" "}
                  <Button
                    variant="contained"
                    onClick={getResults}
                    disabled={!tokenState.validQuery}
                  >
                    Get Results
                  </Button>{" "}
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={clearResults}>
                Clear Results
              </Button>
            </Grid>
          </Grid>

          <div className="results-box">
            {isLoadingPropertiesResults ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <div className="loader"></div>
              </Box>
            ) : propertiesResults ? (
              <pre>
                <JsonView
                  data={propertiesResults}
                  shouldInitiallyExpand={allExpanded}
                  style={defaultStyles}
                ></JsonView>
              </pre>
            ) : null}
          </div>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={copyToClipboard}
                disabled={
                  !tokenState.validQuery || !state.queryName || !state.version
                }
              >
                Copy JSON Output to Clipboard
              </Button>
            </Grid>
            <Grid item xs={6}>
              <JsonToCsv
                propertyData={propertiesResults}
                selectedSchemaName={selectedSchemaName}
              />
            </Grid>
          </Grid>
          <Snackbar
            open={isClipboardNotificationOpen}
            autoHideDuration={4000}
            onClose={closeClipboardNoficiation}
            message={copyClipboardMsg}
            action={copyToClipboardNotificationJSX}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          />
        </Box>
      </Paper>
    </>
  );
}

export default ResultsPanel;
