import React, { useContext, useState } from "react";
import { StateContext } from "../hooks/StateContext";
import "../App.css";
import {
  Select,
  MenuItem,
  Grid,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";

/**
 * @description ParameterRow Component represents a single row of parameters for the QueryBuilder. It allows the user to select a key,
 * operator, value, and condition for a given query parameter. The component highlights invalid rows and provides an option to remove a parameter.
 *
 * @function
 * @param {Object} props - Component props.
 * @param {Object} props.param - Contains details of the parameter, such as key, operator, value, condition, and validity.
 * @param {number} props.index - Index of the parameter in the list.
 * @param {Function} props.onParamChange - Callback function for when a parameter changes.
 * @param {Function} props.onRemoveParam - Callback function for when a parameter is to be removed.
 * @param {Array} props.stringOperators - List of string operators valid for the query.
 * @param {Array} props.operators - List of all available operators for the query.
 * @param {Array} props.conditions - List of conditions valid for the query.
 *
 * @returns {JSX.Element} A rendered row of parameter selection tools.
 *
 * @dependencies React, useContext from 'react' and StateContext.
 */
const ParameterRow = ({
  param,
  index,
  onParamChange,
  onRemoveParam,
  stringOperators,
  operators,
  conditions,
  lastParameterIndex,
}) => {
  const { state } = useContext(StateContext);

  // Destructure the individual properties of the passed param
  const { key, operator, value, condition, isValid } = param;

  // If the first dropdown (name=key) is opened and a selection is made other dropdowns can be selected (disabled=false)
  // this is created to guide user flow, and prevent error that occurs when dropdowns other than the first one (name=key) are selected first
  const [allowDropdown, setAllowDropdown] = useState(true);

  // Use a conditional operator to determine the CSS class based on validity
  const isValidClass = isValid ? "parameter valid" : "parameter invalid";

  const handleInputChange = (event) => {
    onParamChange(index, event);

    // console.log("this is event and index", event, index);
    if (event.target.name === "key") {
      setAllowDropdown(false);
    }
  };

  // Copy Property Schema Properties and Delete PageNum and PageLength from Dropdown
  const propertrySchemaProperties = { ...state.propertySchema.properties };
  delete propertrySchemaProperties.pageNum;
  delete propertrySchemaProperties.pageLength;

  return (
    <Grid
      container
      key={index}
      className={isValidClass}
      spacing={0}
      sx={{ padding: "10px", marginBottom: "10px" }}
      data-testid="parameter-row"
    >
      <Grid item xs={2.75} sx={{ width: "100%" }}>
        <Select
          name="key"
          value={key}
          onChange={handleInputChange}
          displayEmpty
          sx={{ flexShrink: 1, width: "100%" }}
        >
          {/* TODO: re-incorporate a --select-- by default in dropdown        
          <MenuItem value="-- select --">-- select --</MenuItem> */}
          {propertrySchemaProperties &&
            Object.keys(propertrySchemaProperties || {}).map((k, i) => (
              <MenuItem key={i} value={k}>
                {k}
              </MenuItem>
            ))}
        </Select>
      </Grid>
      <Grid item xs={2.75} sx={{ width: "100%", paddingLeft: "16px" }}>
        <Select
          disabled={allowDropdown}
          name="operator"
          value={operator || "-- select --"}
          onChange={handleInputChange}
          displayEmpty
          sx={{ flexShrink: 1, width: "100%" }}
        >
          <MenuItem value="-- select --">-- select -- </MenuItem>
          {state.inputTypes[index] && state.inputTypes[index] === "enum"
            ? stringOperators.map((o, i) => (
                <MenuItem key={i} value={o}>
                  {o}
                </MenuItem>
              ))
            : operators.map((o, i) => (
                <MenuItem key={i} value={o}>
                  {o}
                </MenuItem>
              ))}
        </Select>
      </Grid>
      <Grid item xs={2.75} sx={{ width: "100%", paddingLeft: "16px" }}>
        {state.inputTypes[index] && state.inputTypes[index] === "enum" ? (
          <Select
            disabled={allowDropdown}
            name="value"
            value={value || "-- select --"}
            onChange={handleInputChange}
            displayEmpty
            sx={{ flexShrink: 1, width: "100%" }}
          >
            <MenuItem value="-- select --">-- select --</MenuItem>
            {(state.propertySchema.properties[key]?.enum || []).map((e, i) => (
              <MenuItem key={i} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField
            disabled={allowDropdown}
            type="text"
            name="value"
            placeholder="Value"
            value={value}
            onChange={handleInputChange}
            sx={{ width: "100%" }}
          />
        )}
      </Grid>
      <Grid item xs={2.75} sx={{ width: "100%", paddingLeft: "16px" }}>
        <Select
          disabled={allowDropdown}
          name="condition"
          value={condition || "-- select --"}
          onChange={handleInputChange}
          displayEmpty
          sx={{ flexShrink: 1, width: "100%" }}
        >
          <MenuItem value="-- select --">-- select --</MenuItem>
          {conditions && index === lastParameterIndex ? (
            <MenuItem value="AND">END</MenuItem>
          ) : (
            conditions.map((c, i) => (
              <MenuItem key={i} value={c}>
                {c}
              </MenuItem>
            ))
          )}
        </Select>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          width: "100%",
          paddingLeft: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title="Remove Row" arrow>
          <Button
            variant="contained"
            size="small"
            onClick={() => onRemoveParam(index)}
            sx={{
              backgroundColor: "error.main",
              minWidth: "0px",
              height: "75%",
              width: "75%",
              fontSize: "20px",
              ":hover": {
                bgcolor: "error.dark",
              },
            }}
          >
            X
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ParameterRow;
