import { getLocalEnvBaseUrl } from "./getLocalEnvBaseUrl";

// Local development Environment - update to test locally the environment you desire
// choices for strings: 'nprd', 'prd'. any other input will result in local 'qa' env
const currentLocalTestingEnv = "nprd";

// When code is deployed, first condition will run because API_HOST (located in app.js) will be overriden with the baseURL (which includes https)
// locally, the second condition would run based on the currentEnv variable above
export const getFetchBaseUrl = (setFetchBaseUrl, API_HOST) => {
  if (API_HOST.includes("https")) {
    setFetchBaseUrl(API_HOST);
  } else {
    setFetchBaseUrl(getLocalEnvBaseUrl(currentLocalTestingEnv));
  }
};
