import { createContext, useState } from "react";

export const TokenContext = createContext();

/**
 * TokenContextProvider for managing authentication token state in React.
 *
 * @description
 * Provides a context for authentication token state management across the React application. The `TokenContextProvider`
 * component initializes the token-related state and provides a way for child components to consume and update this state.
 *
 * Global State Parameters:
 * @param {string} token - The authentication token received after user login.
 * @param {boolean} validToken - Flag to indicate whether the token is valid.
 * @param {boolean} validQuery - Flag to indicate whether the current query with the token is valid.
 * @param {boolean} validResults - Flag to indicate whether the results retrieved using the token are valid.
 *
 * Note: This context is crucial for handling user authentication state and permissions throughout the application.
 * Components can subscribe to this context to react to changes in authentication state.
 *
 * @returns {Context.Provider} A provider component that allows consuming components to subscribe to token state changes.
 */
export const TokenContextProvider = ({ children }) => {
  const [tokenState, setToken] = useState({
    token: "",
    validToken: false,
    validQuery: false,
    validResults: false,
  });

  return (
    <TokenContext.Provider value={{ tokenState, setToken }}>
      {children}
    </TokenContext.Provider>
  );
};
