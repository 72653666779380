import React, { useContext, useEffect, useState } from "react";
import "../App.css";

import {
  Button,
  Alert,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Paper,
  Box,
  TextField,
} from "@mui/material";

import { StateContext } from "../hooks/StateContext";
import { TokenContext } from "../hooks/TokenContext";
import ParameterRow from "./ParameterRow";
import { validateParameters } from "../utils/validateParameters";
import { validatePagination } from "../utils/validatePagination";

/**
 * @description QueryBuilder Component is responsible for creating dynamic queries. It provides an interface to add, remove, and validate
 * parameters for the query, and shows the output in a structured format.
 *
 * @function
 * @param selectedSchemaName contains the name of the selected schema, which is used to Display the schema name in the jsx.
 * @param updateOutput - Generates and updates the output based on the current state
 *
 * @param {Array} stringOperators - List of string operators valid for the query.
 * @param {Array} operators - List of all available operators for the query.
 * @param {Array} conditions - List of conditions valid for the query.
 *
 * @returns {JSX.Element} A rendered component with tools for query building.
 *
 */

const QueryBuilder = ({ updateOutput, selectedSchemaName }) => {
  const { state, setState } = useContext(StateContext);
  const { setToken } = useContext(TokenContext);

  const [validityArray, setValidityArray] = useState([]);

  const operators = ["=", "!=", "<", ">", "<=", ">="];
  const stringOperators = ["=", "!="];
  const conditions = ["AND", "OR"];
  const [limitedParameterRows, setLimitedParameterRows] = useState(false);

  // lastParameterIndex is used inside ParamterRow.js. For the last parameter row, the 'condition' statement will be "END"
  let lastParameterIndex = state.parameters.length - 1;

  // Limit the amount of parameters rows, by disabling the 'add parameters' button when parameter limit met
  useEffect(() => {
    if (lastParameterIndex >= state.parameterRowLimit-1) {
      setLimitedParameterRows(true);
    } else {
      setLimitedParameterRows(false);
    }
  }, [state.parameters]);


  useEffect(() => {
    updateOutput();
  }, [state.parameters]);

  useEffect(() => {
    const isAllRowsValid = validityArray.every((item) => item.valid);

    const areQueryFieldsFilled = state.queryName.trim() && state.version.trim();

    setToken((prevTokenState) => ({
      ...prevTokenState,
      validQuery:
        isAllRowsValid && areQueryFieldsFilled && validityArray.length > 0
          ? true
          : false,
    }));
  }, [validityArray, state.queryName, state.version]);
  /**
   * @description Handles removal of a parameter by its index.
   *
   * @function
   * @param {number} index - Index of the parameter to be removed.
   */

  const handleRemoveParam = (index) => {
    const parameters = [...state.parameters];
    const inputTypes = [...state.inputTypes];

    // Remove parameter and its input type
    parameters.splice(index, 1);
    inputTypes.splice(index, 1);

    // Remove the item with the matching index from the validityArray
    const updatedValidityArray = validityArray.filter(
      (item) => item.index !== index
    );

    // Adjust the indices in updatedValidityArray since the index has changed after removal
    updatedValidityArray.forEach((item) => {
      if (item.index > index) {
        item.index -= 1;
      }
    });

    setValidityArray(updatedValidityArray);

    // Remove associated error from the errorLog
    const errorLog = { ...state.errorLog };
    delete errorLog[index];

    // Adjust the keys in errorLog since the index has changed after removal
    const updatedErrorLog = Object.fromEntries(
      Object.entries(errorLog).map(([key, value]) => {
        if (key > index) {
          return [key - 1, value];
        }
        return [key, value];
      })
    );

    setState({
      ...state,
      parameters,
      inputTypes,
      errorLog: updatedErrorLog,
    });

    console.log("paraneters ");
  };

  /**
   * @description Handles changes made to a parameter.
   *
   * @function
   * @param {number} index - Index of the parameter where changes occurred.
   * @param {Event} event - The event object containing details of the change.
   */
  const handleParamChange = (index, event) => {
    const { name, value } = event.target;

    if (name === "key" && value === "-- select --") {
      setToken((prevTokenState) => ({
        ...prevTokenState,
        validQuery: false,
      }));
    }

    const parameters = [...state.parameters];
    parameters[index][name] = value;
    setState({ ...state, parameters });

    validateParameters(
      index,
      stringOperators,
      state,
      setState,
      setToken,
      setValidityArray
    );
  };

  /**
   * @description Handles general state changes, e.g., queryName or version.
   *
   * @function
   * @param {Event} event - The event object containing details of the change.
   */

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
    if (name === "pageNum" || name === "pageLength") {
      validatePagination(name, value, state, setState);
    }
  };

  /**
   * @description Handles addition of a new parameter.
   *
   * @function
   */

  const handleAddParam = () => {
    setState(
      (prevState) => ({
        ...prevState,
        parameters: [
          ...prevState.parameters,
          { key: "", operator: "", value: "", condition: "", isValid: false },
        ],
        inputTypes: [...prevState.inputTypes, "text"],
      }),
      updateOutput()
    );

    setToken((prevTokenState) => ({
      ...prevTokenState,
      validQuery: false,
    }));
  };

  /**
   * @description Clears all parameters
   *
   * @function
   */
  const clearParameters = () => {
    setState((prevState) => ({
      ...prevState,
      parameters: [],
      inputTypes: [],
      errorLog: {},
      output: "",
      log: "",
    }));

    setToken((prevTokenState) => ({
      ...prevTokenState,
      validQuery: false,
    }));
  };

  return (
    <>
      <Paper elevation={3} sx={{ padding: "20px" }}>
        <Grid container alignItems="center">
          <Typography variant="h5" sx={{ paddingBottom: "20px" }}>
            {selectedSchemaName}: Create Query
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Tooltip arrow placement="top" title="Name for this Query">
                <TextField
                  required
                  name="queryName"
                  id="outlined-required"
                  label="Query Name"
                  onChange={handleChange}
                  value={state.queryName}
                  sx={{ backgroundColor: "white", width: "100%" }}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={3}>
              <Tooltip
                arrow
                placement="top"
                title="Version Number for this Query"
              >
                <TextField
                  required
                  name="version"
                  id="outlined-required"
                  label="Version"
                  onChange={handleChange}
                  value={state.version}
                  sx={{ backgroundColor: "white", width: "100%" }}
                />
              </Tooltip>
            </Grid>

            {/* <Grid container direction="row"> */}
            <Grid item xs={3}>
              <Tooltip
                arrow
                placement="top"
                title="The current page number for the results you are querying. For more context - see 'meta_data' within the result body for the 'total_pages'. Page Number corresponds with the 'current_page' you are viewing within the total number of pages."
              >
                <TextField
                  required
                  name="pageNum"
                  id="outlined-required"
                  label="Page Number"
                  value={state.pageNum}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white", width: "100%" }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip
                arrow
                placement="top"
                title="Represents the number of objects you retrieve within the Page you are querying. Eg. (2) Responses within Page #2 will output 2 entries, within Page #2, so long as there is enough data to present."
              >
                <TextField
                  required
                  name="pageLength"
                  id="outlined-required"
                  label="Number of Objects"
                  value={state.pageLength}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white", width: "100%" }}
                />
              </Tooltip>
            </Grid>
            {/* </Grid> */}
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item sx={{ flexGrow: 1 }}>
                <Typography variant="h5" sx={{ paddingTop: "20px" }}>
                  Parameters:
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Tooltip
                  arrow
                  placement="left"
                  title="Add Query Parameters (1 minimum, 8 max)"
                >
                  <Button
                    disabled={limitedParameterRows}
                    variant="contained"
                    onClick={handleAddParam}
                  >
                    Add
                  </Button>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Tooltip
                  arrow
                  placement="right"
                  title="Clear all Query Parameters"
                >
                  <Button variant="outlined" onClick={clearParameters}>
                    Clear
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            {state.parameters.map((param, index) => (
              <ParameterRow
                key={index}
                param={param}
                index={index}
                inputType={state.inputTypes[index]}
                onParamChange={handleParamChange}
                onRemoveParam={handleRemoveParam}
                stringOperators={stringOperators}
                operators={operators}
                conditions={conditions}
                lastParameterIndex={lastParameterIndex}
              />
            ))}
          </Box>
          <Stack
            sx={{ width: "100%", paddingTop: "0", paddingBottom: "0" }}
            spacing={1}
          >
            {state.errorLog &&
              Object.values(state.errorLog).map((error, index) => (
                <Alert severity="error" key={index}>
                  Parameter "{error.key}": {error.message}
                </Alert>
              ))}
          </Stack>
        </Grid>
      </Paper>
    </>
  );
};

export default QueryBuilder;
