import React, { useEffect, useContext, useCallback, useState } from "react";
import { StateContext } from "./hooks/StateContext";
import { TokenContext } from "./hooks/TokenContext";

import QueryBuilder from "./components/QueryBuilder";
import ResultsPanel from "./components/ResultsPanel";
import HomePage from "./components/HomePage";
import SideBarSchema from "./components/SideBarSchema";
import FAQ from "./components/FAQ";
import customTheme from "./muitheme.js";
import "./App.css";

import {
  Grid,
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { getFetchBaseUrl } from "./utils/getFetchBaseUrl.js";
import { updateOutputHelper } from "./utils/updateOutputHelper.js";

/** 
 * ENV and API_HOST are replaced using the sed command within the buildspec.yml file.The strings to the right of these variables will be replaced
 * with both the env(eg.nprod, qa, etc.) and the API_HOST(base url for that specific environment).These 'environment variables' are defined within
 * the AWS secrets manager for the various environments of this application
 */

const ENV = "prd"; // DO NOT DELETE even if unused in file - read docstring above
const API_HOST = "https://api.moodysanalytics.com/cre-apis/cre/v3";

/**
 * @description The main React component for the Dynamic Query Workbench application, which provides an interactive interface for users
 * to construct and execute database queries. The component integrates various components:
 * --- Toolbar: provide navigation buttons, as well as FAQ modal
 * --- Homepage: Provides general description of workbench, as well as a visual user walkthrough
 * --- SideBarSchema: sidebar panel provides users ability to select the schema they want to query data from
 * --- Query builder: Builds the Query - including query metadata, and each Parameter Row
 * --- Results panel: displays the results of the query. Gives users ability to copy the query in JSON form, as well as download the query results as a .csv
 *
 * It also includes navigation controls like Home and FAQ buttons, and manages
 * global state using React's useContext system. The app adapts its fetch base URL based on the deployment environment.
 *
 * The App component orchestrates the layout of subcomponents and ensures the correct data flow between user actions and
 * state management. It is designed to dynamically switch between different
 * schemas and query configurations, reflecting changes instantly in the results panel with a click of a button.
 */
function App() {
  const { state, setState } = useContext(StateContext);
  const { tokenState, setToken } = useContext(TokenContext);
  const [selectedSchemaName, setSelectedSchemaName] = useState("");
  const [fetchBaseUrl, setFetchBaseUrl] = useState("");
  const [openFAQ, setOpenFAQ] = useState(false);
  const sidebarWidth = 240;

  useEffect(() => {
    getFetchBaseUrl(setFetchBaseUrl, API_HOST);
  }, []);

  /**
   * @description Generates and updates the output based on the current state. 'output' includes: query name, version,
   * data for each parameter row.
   * Ensures that updates occur only when there's a change in the generated output to prevent unnecessary state updates.
   */
  const updateOutput = useCallback(() => {
    updateOutputHelper(state, setState);
  }, [state, setState]);

  // Triggers the 'output' update whenever relevant state parameters change.
  useEffect(() => {
    updateOutput();
  }, [
    state.queryName,
    state.version,
    state.parameters,
    state.pageNum,
    state.pageLength,
  ]);

  /**
   * @description: When user clicks Home button, brings user to homepage. This is done by resetting the token context, which will in turn set ValidToken to false, thus displaying the HomePage component
   */
  function goToHomepage() {
    setToken({
      token: "",
      validToken: false,
      validQuery: false,
      validResults: false,
    });
  }

  // sets the openFAQ boolean to true. This state is passesd down to the FAQ component.
  function openFAQFunction() {
    setOpenFAQ(true);
  }

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${sidebarWidth}px)`,
              ml: `${sidebarWidth}px`,
            }}
          >
            <Toolbar display={"flex"}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Dynamic Query Workbench v 2.1 [For Internal Use Only]
              </Typography>
              <Button
                onClick={goToHomepage}
                variant="contained"
                color="secondary"
                sx={{ width: "100px" }}
              >
                Home
              </Button>
              <Button
                onClick={openFAQFunction}
                variant="contained"
                color="secondary"
                sx={{ width: "100px", marginLeft: "30px" }}
              >
                FAQ
              </Button>
              {openFAQ && <FAQ openFAQ={openFAQ} setOpenFAQ={setOpenFAQ} />}
            </Toolbar>
          </AppBar>

          <SideBarSchema
            sidebarWidth={sidebarWidth}
            setSelectedSchemaName={setSelectedSchemaName}
            fetchBaseUrl={fetchBaseUrl}
          />
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
          >
            <Toolbar />
            {tokenState.validToken ? (
              <Grid container spacing={6} p={2}>
                <Grid item xs={6}>
                  <QueryBuilder
                    updateOutput={updateOutput}
                    selectedSchemaName={selectedSchemaName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ResultsPanel
                    fetchBaseUrl={fetchBaseUrl}
                    selectedSchemaName={selectedSchemaName}
                  />
                </Grid>
              </Grid>
            ) : (
              <HomePage />
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
