import React from "react";

import { createContext, useState } from "react";

export const StateContext = createContext();

/**
 * StateContext and StateContextProvider for global state management in Dynamic Query Builder
 *
 * @description
 * Provides a context for global state management across the React application. The `StateContextProvider`
 * component initializes the global state and allows child components to consume and update the global state.
 * All compoments consume the state context provider as global state,
 *
 * Global State Parameters:
 * @param {string} queryName - The name of the current query.
 * @param {string} version - The version of the query or the application.
 * @param {number} pageNum - The current page number for paginated results.
 * @param {number} pageLength - The number of results per page.
 * @param {Array} parameters - An array of parameters for the query.
 * @param {number} parameterRowLimit - The max number of Parameter Rows a user can add
 * @param {string} output - The output from the query or operation.
 * @param {string} log - Log messages from operations.
 * @param {Object} propertySchema - The JSON schema based on the selected schema.
 * @param {Object} errorLog - Logs for errors encountered during operations.
 * @param {Array} inputTypes - The types of inputs available for queries.
 * @param {string} selectedSchema - The default or selected JSON schema file.
 *
 * Note: Changes to this context might require updates to other components like `SideBarSchema.js`
 * that rely on this state, to ensure consistency across the application.
 *
 * @returns {Context.Provider} A provider component that allows consuming components to subscribe to context changes.
 */

export const StateContextProvider = ({ children }) => {
  const [state, setState] = useState({
    queryName: "query1",
    version: "1",
    pageNum: 1,
    pageLength: 75,
    parameters: [],
    parameterRowLimit: 8,
    output: "",
    log: "",
    propertySchema: {},
    errorLog: {},
    inputTypes: [],
    selectedSchema: "propertySchema.json",
  });

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};
