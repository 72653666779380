import Ajv from "ajv";

/**
 * Validates a specific parameter within a state object's parameters array using the Ajv JSON schema validator.
 * It applies validation checks based on the parameter type and operator constraints. Additionally, it manages
 * error messages and updates the validity status within the state and token state management functions.
 *
 * @param {number} index - The index of the parameter row within the state parameters array to be validated.
 * @param {Array} stringOperators - A list of valid operators that can be used with string type parameters.
 * @param {Object} state - The current global state object - which contains parameters, schemas information, etc.
 * @param {Function} setState - The state setter function to update the global state object.
 * @param {Function} setToken - The state setter function to update the token state for query validity.
 * @param {Function} setValidityArray - The function to update an array, which tracks the validity of each parameter row.
 */

export const validateParameters = (
  index,
  stringOperators,
  state,
  setState,
  setToken,
  setValidityArray
) => {
  const ajv = new Ajv();

  const param = state.parameters[index];
  const schema = state.propertySchema.properties[param.key];

  let valid;
  let errorMessage;

  if (!schema) {
    return;
  }

  try {
    // Validate the parameter based on its type defined in the schema
    if (schema.type === "integer") {
      valid = ajv.validate(schema, parseInt(param.value));
    } else if (schema.type === "number") {
      valid = ajv.validate(schema, parseFloat(param.value));
    } else {
      valid = ajv.validate(schema, param.value);
    }
    if (!valid) {
      errorMessage = ajv.errorsText(ajv.errors);
      if (schema.examples) {
        errorMessage += ` Example values: ${schema.examples.join(",")}`;
      }
    }
  } catch (err) {
    // If validation throws an error, mark as invalid and set the error message
    valid = false;
    errorMessage = `Error during Ajv validation: ${err.message}`;
  }

  if (param.value && !param.operator) {
    valid = false;
    errorMessage = "You must choose an operator";
  }

  if (
    param.value &&
    (schema.type === "string" || schema.enum) &&
    !stringOperators.includes(param.operator)
  ) {
    valid = false;
    errorMessage = `You cannot apply operator ${param.operator} to type string`;
  }

  const parameters = [...state.parameters];
  parameters[index].isValid = valid;

  let errorLog = { ...state.errorLog };
  if (!valid) {
    errorLog = {
      ...errorLog,
      [index]: { key: param.key, message: errorMessage },
    };
  } else if (!param.condition || param.condition === "-- select --") {
    valid = false;
    errorLog = {
      ...errorLog,
      [index]: { key: param.key, message: "You must choose a condition" },
    };
    parameters[index].isValid = false;
  } else {
    const { [index]: _, ...rest } = errorLog;
    errorLog = rest;
  }

  // Update the type of input field to be displayed based on the parameter's schema
  const inputTypes = [...state.inputTypes];
  if (schema.enum) {
    inputTypes[index] = "enum";
  } else {
    inputTypes[index] = "text";
  }

  setState((prevState) => ({
    ...prevState,
    errorLog,
    parameters,
    inputTypes,
  }));

  setToken((prevTokenState) => ({
    ...prevTokenState,
    validQuery: valid ? true : false,
  }));

  // store state for each index and overwrite duplicates

  setValidityArray((prevArray) => {
    const foundIndex = prevArray.findIndex((item) => item.index === index);
    if (foundIndex !== -1) {
      // If the index is found, update its validity.
      const newArray = [...prevArray];
      newArray[foundIndex].valid = valid;
      return newArray;
    } else {
      // If the index is not found, add a new object.
      return [...prevArray, { index, valid }];
    }
  });

  // If state.version is not set, mark the token state as invalid
  if (valid && (!state.version || !state.version)) {
    setToken((prevTokenState) => ({
      ...prevTokenState,
      validQuery: false,
    }));
  }
};
