import React, { useEffect, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * @description: Renders a FAQ dialog modal from the app Toolbar. useEffect here sets the focus to the DialogContentText, which is a typically desired modal behavior.
 * @param: Takes state from App component - openFAQ and setOpenFAQ. openFAQ is a boolean which when set to true, will show the FAQ modal. setOpenFAQ is a setter. This is set to be true, when the user clicks on the FAQ button in the App.js. When the user closes the modal, this boolean is set to false.
 * @returns: Renders a FAQ dialog modal, that is closeable by clicking outside of the modal, or clicking the close button.
 * @example: https://mui.com/material-ui/react-dialog/#scrolling-long-content
 */

function FAQ({ openFAQ, setOpenFAQ }) {
  /**
   * @description: Handles the closing of the FAQ modal.
   * @returns: Sets the openFAQ state to false.
   */
  const handleClose = () => {
    setOpenFAQ(false);
  };

  const descriptionElementRef = useRef(null);

  /**
   * @description: Uses the useEffect hook to set focus on the DialogContentText node when the FAQ modal is open.
   */

  useEffect(() => {
    if (openFAQ) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus(); // focus placed onto the DialogContextText node.
      }
    }
  }, [openFAQ]);

  /**
   * @description: Renders an accordion item containing a question and its corresponding answer.
   * @param: {string} question - Represents the FAQ question.
   * @param: {string} answer - Represents the corresponding answer to the FAQ question.
   * @returns: JSX that renders an accordion component containing the given question and answer.
   */

  function RenderAccordionItem(question, answer) {
    return (
      <Accordion sx={{ backgroundColor: "white" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: "bold" }}>{question}</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ backgroundColor: "white" }}>
          <Typography>{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <>
      <Dialog
        open={openFAQ}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle variant="h4" sx={{ textAlign: "center" }}>
          Frequently Asked Questions:{" "}
        </DialogTitle>
        <DialogContent dividers={false} sx={{ margin: "-30px" }}>
          <DialogContent ref={descriptionElementRef} tabIndex={-1}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {RenderAccordionItem(
                "What is Dynamic Query Workbench?",
                "The Dynamic Query Workbench allows you to easily introspect Moody's Analytics CRE databases, by creating JSON payloads to query databases, display the databases' response. The workbench also provides ways for users to easily copy the JSON Payload to their local clipboard, as well as download the database results into a .CSV file."
              )}
              {RenderAccordionItem(
                "How do I access a walkthrough of using this Workbench?",
                "A step by step walkthrough is present on the landing page."
              )}
              {RenderAccordionItem(
                "How many parameters can I add?",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
              )}
              {RenderAccordionItem(
                "What schema's do I have access too?",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
              )}
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FAQ;
